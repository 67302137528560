<template>
  <insert-modal :modal="modal" title="New Environment" :type="type" :model="currentEnvironment">
    <div class="mb-2">
    </div>
    servers
    <multi-select :items="servers"
                  :selected="currentEnvironment.servers"
                  display="name"
                  identifier="_id"></multi-select>
  </insert-modal>
  <div class="container">
    <h5>{{project.name}}</h5>
    <div class="row mb-3">
      <div class="col-lg-3">
        <button class="btn-success btn" v-on:click="newEnv()">
          <span class="fa fa-plus"></span>
          New Environment
        </button>
      </div>
    </div>
    <div class="row" v-if="project.environments">
      <div class="col-lg-4" v-for="environment in project.environments" v-bind:key="environment">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title" >

              {{ environment.name }}
            </h5>
            <div v-for="server in environment.servers" v-bind:key="server">
              <div class="card">
                <div class="card-body">
                  {{ servers.find(x => x._id == server).name }}
                </div>
              </div>
              <div class="mb-2"></div>
            </div>
            <div>
              <button class="btn btn-success me-1" v-on:click="edit(environment)">
                <span class="fa fa-pen-alt" ></span>
                Edit
              </button>
              <button class="btn btn-danger" v-on:click="edit(environment)">
                <span class="fa fa-trash-alt" ></span>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {api} from "../services/api";
import MultiSelect from "input-extensions/src/components/MultiSelect";
import InsertModal from 'modal/src/components/InsertModal'

export default {
  name: "Settings.vue",
  components: {MultiSelect,InsertModal},
  data() {
    return {
      type:{name:"text"},
      modal : {
        action:(ent) => {
          if(!this.project.environments) {
            this.project.environments = [];
          }
          let isExists = this.project.environments.indexOf(ent) > -1;
          if(!isExists) {
            this.project.environments.push(this.currentEnvironment);
          }

          api.projects.post("",this.project)
        },
        isShow:false
      },
      currentEnvironment:{},
      project:{},
      servers:[]
    }
  },
  methods: {
    newEnv() {
      this.edit({
        servers:[],
        name:""
      })
    },
    edit(environment) {
      this.currentEnvironment = environment;
      this.modal.isShow = true
    },
  },
  async created() {
    let servers = await api.servers.post("get",{
      filter:{},skip:0,limit:0
    });
    this.servers = servers.data;
    this.project = await api.projects.get(this.$route.params.id);
  }
}
</script>

<style scoped>
.modal{
  display: block !important;
}
</style>